import React, { useEffect } from 'react';
import './App.css';
import './static/sass/App.scss';
import MenuTop from './components/menutop/MenuTop';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import theme from 'Theme';
import MainNavigation from './navigation/MainNavigation';
import SnackbarMessage from './components/snackbar/SnackbarMessage';
import { UserActionModals } from 'users/actions/UserActionButtons';
import { VideoActionModals } from 'videos/actions/VideoActionButtons';
import { InvitationActionModals } from 'invitations/actions/InvitationActionButtons';
import { getConfigFromEndpointsApi } from './http/endpointsApi/endpointsApi';
import { getConfig, saveAppConfigFromApi } from 'config/appConfig';
import { QueryClient, QueryClientProvider } from 'react-query';
import { getLoggedUserId, getTokenPayload } from './http/authApi/token';
import { initFlags } from '@content-technology-partners-ltd/shared-flags-react';

declare module '@mui/styles/defaultTheme' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}

const App = () => {
	useEffect(() => {
		getConfigFromEndpointsApi().then(saveAppConfigFromApi);

		const token = getTokenPayload();
		const identifier = getLoggedUserId();
		const appConfig = getConfig();
		initFlags({
			evaluationContext: {
				identifier: identifier || undefined,
				identity: token
					? {
							traits: {
								isAdmin: !!token.isAdmin,
								isContributor: !!token.isContributor,
								isPartnerStaff: !!token.isPartnerStaff,
								isPublisher: !!token.isPublisher
							}
					  }
					: undefined
			},
			environmentId: appConfig.FLAGSMITH_ENVIRONMENT_ID,
			apiUrl: appConfig.FLAGSMITH_API_URL,
			pollingInterval: appConfig.FLAGSMITH_POLLING_INTERVAL_MS
		}).catch(e => console.error(e) /* replace with Sentry */);
	}, []);

	const queryClient = new QueryClient();

	return (
		<QueryClientProvider client={queryClient}>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<div className="App">
						<MenuTop />
						<MainNavigation />
						<SnackbarMessage />
						<UserActionModals />
						<VideoActionModals />
						<InvitationActionModals />
					</div>
				</ThemeProvider>
			</StyledEngineProvider>
		</QueryClientProvider>
	);
};

export default App;
